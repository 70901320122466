<template>
  <div :class="$style.contacts">
    <div :class="$style.wrapper">
      <a :href="$configData.addwine_link + slug" target="_blank">
        <el-button size="small" type="primary">Просмотреть</el-button>
      </a>
      <el-button
        size="small"
        type="primary"
        :class="$style.button"
        @click="submitForm()"
        >Сохранить контент</el-button
      >
    </div>
    <el-form
      :model="form"
      :rules="rules"
      ref="form"
      label-position="right"
      label-width="12.5rem"
      :class="$style.form"
    >
      <h2 :class="$style.name">Страница: Как сделать заказ</h2>
      <div :class="$style.orderInfo">
        <el-form-item label="Заголовок" prop="title">
          <el-input v-model="form.title" />
        </el-form-item>

        <el-form-item label="SEO-тексты">
          <SeoTextsBlock :seoTexts="form.seoTexts" @getSeo="getSeoTexts" />
        </el-form-item>
      </div>

      <el-form-item label="SEO-FAQ">
        <SeoFaqsBlock :seoFAQs="form.seoFaqs" @getSeo="getSeoFaqs" />
      </el-form-item>

      <AdwTabs
        :tabs="tabs"
        :active-tab="activeTab"
        color="gold"
        underline="underline-gray"
        @change="changeTab"
      />
      <el-button
        size="small"
        type="primary"
        :class="$style.addSection"
        @click="addSection()"
      >
        Добавить секцию
      </el-button>

      <template v-for="(elem, index) in form.orderVariations">
        <div v-if="elem.title === activeTab" :key="index">
          <Container @drop="onDrop" :non-drag-area-selector="'.drag-disabled'">
            <Draggable v-for="(section, ind) in elem.content" :key="ind">
              <div class="draggable-item">
                <el-form-item label="Заголовок" class="drag-disabled">
                  <el-input v-model="section.title" />
                </el-form-item>
                <div :class="$style.sectionBlock">
                  <div :class="$style.sectionItem">
                    <Icon name="draggable" :class="$style.icon" />
                    <div :class="$style.uploader">
                      <Uploader
                        without-caption
                        :limit="1"
                        :files="
                          section.image ? [{ original: section.image }] : []
                        "
                        @upload="uploadImages($event, ind)"
                      />
                    </div>
                    <el-form-item
                      label-width="0"
                      :prop="
                        'orderVariations.' + index + '.content.' + ind + '.text'
                      "
                      :rules="rules.orderVariations.content.text"
                    >
                      <TextEditor
                        :value.sync="section.text"
                        :is-image="false"
                        :is-media="false"
                        :height="400"
                      />
                    </el-form-item>
                  </div>

                  <div :class="$style.removeElem">
                    <el-button
                      type="danger"
                      plain
                      circle
                      icon="el-icon-delete"
                      :class="$style.remove"
                      @click="removeSection(ind)"
                    />
                  </div>
                </div>
              </div>
            </Draggable>
          </Container>
        </div>
      </template>

      <SeoBlock
        :title.sync="form.seo.title"
        :description.sync="form.seo.description"
        :og-title.sync="form.seo.ogTitle"
        :og-description.sync="form.seo.ogDescription"
        :og-image.sync="form.seo.ogImage"
        :class="$style.seo"
      />
    </el-form>
  </div>
</template>

<script>
import delivery from '@/delivery'
import SeoBlock from '@/components/moleculs/SEO.vue'
import SeoTextsBlock from '@/components/organisms/SeoTextsBlock.vue'
import SeoFaqsBlock from '@/components/organisms/SeoFaqsBlock.vue'
import Uploader from '@/components/moleculs/AddwineUploader.vue'
import TextEditor from '@/components/atoms/TextEditor.vue'
import Icon from '@/components/atoms/Icon'

export default {
  components: {
    SeoBlock,
    SeoTextsBlock,
    SeoFaqsBlock,
    Uploader,
    TextEditor,
    Icon,
  },

  data() {
    return {
      tabs: ['На сайте', 'По телефону', 'Чат WhatsApp'],
      activeTab: 'На сайте',
      slug: '',
      form: {
        title: '',
        seoTexts: [],
        seoFaqs: [],
        orderVariations: [{ title: '', content: [] }],
        seo: {
          title: '',
          description: '',
          ogTitle: '',
          ogDescription: '',
          ogImage: '',
        },
      },
      rules: {
        title: [
          {
            required: true,
            message: 'Пожалуйста, заполните поле',
            trigger: 'blur',
          },
        ],
        orderVariations: {
          content: {
            text: [
              {
                required: true,
                message: 'Пожалуйста, заполните поле',
                trigger: 'blur',
              },
            ],
          },
        },
      },
    }
  },

  async created() {
    await this.getContent()
  },

  methods: {
    applyDrag(arr, dragResult) {
      const { removedIndex, addedIndex, payload } = dragResult
      if (removedIndex === null && addedIndex === null) return arr

      const result = [...arr]
      let itemToAdd = payload

      if (removedIndex !== null) {
        itemToAdd = result.splice(removedIndex, 1)[0]
      }

      if (addedIndex !== null) {
        result.splice(addedIndex, 0, itemToAdd)
      }

      return result
    },
    onDrop(dropResult) {
      const infoArray = this.form.orderVariations.find(
        (x) => x.title === this.activeTab,
      ).content

      this.form.orderVariations.find(
        (x) => x.title === this.activeTab,
      ).content = this.applyDrag(infoArray, dropResult)
    },

    addSection() {
      this.form.orderVariations
        .find((x) => x.title === this.activeTab)
        .content.push({
          title: '',
          text: '',
          image: '',
        })
    },
    removeSection(index) {
      this.form.orderVariations
        .find((x) => x.title === this.activeTab)
        .content.splice(index, 1)
    },
    uploadImages(images, index) {
      this.form.orderVariations.find((x) => x.title === this.activeTab).content[
        index
      ].image = images.length ? images[0].original : ''
    },
    changeTab(tab) {
      this.activeTab = tab
    },
    getSeoTexts(seoTexts) {
      this.form.seoTexts = seoTexts
    },
    getSeoFaqs(seoFaqs) {
      this.form.seoFaqs = seoFaqs
    },
    async getContent() {
      const loading = this.$loading({
        lock: true,
      })

      const { value, error } =
        await delivery.ContentServiceCore.PagesActions.get(
          'order-info',
          'addwine',
        )
      loading.close()

      if (error) return
      this.slug = value.slug
      this.form = value.content
      this.form.seoTexts =
        this.form?.seoTexts?.map((seoText) => seoText.id) ?? []
      this.form.seoFaqs = this.form?.seoFaqs?.map((seoFaq) => seoFaq.id) ?? []
    },
    async submitForm() {
      this.$refs['form'].validate(async (valid) => {
        if (valid) {
          const loading = this.$loading({
            lock: true,
          })

          const seoTexts = this.form.seoTexts?.map((seoText) => ({
            id: seoText,
          }))

          const seoFaqs = this.form.seoFaqs?.map((seoFaq) => ({
            id: seoFaq,
          }))

          const data = {
            ...this.form,
            seoTexts,
            seoFaqs,
          }

          const result = await delivery.ContentServiceCore.PagesActions.update(
            'order-info',
            'addwine',
            data,
          )
          loading.close()

          if (result.error) {
            this.$message({
              message: 'Ошибка редактирования страницы',
              type: 'error',
            })
            return
          }

          this.$message({
            message: 'Страница успешно отредактирована',
            type: 'success',
          })

          this.getContent()
        }
      })
    },
  },
}
</script>

<style lang="scss" module>
.contacts {
  padding: 0 1rem;
  .wrapper {
    @include stickyWrapper;
    .button {
      margin-left: 1rem;
    }
  }
  .icon {
    width: 2.5rem;
    height: 2.5rem;
  }

  .form {
    h2 {
      text-align: center;
      margin-bottom: 3rem;
    }
    h3 {
      margin-bottom: 1rem;
    }
    .addSection {
      margin: 1rem 0;
    }
    .orderInfo {
      .seoTexts {
        width: 100%;
      }
    }
    .sectionBlock {
      display: flex;
      width: 100%;
      justify-content: center;
      align-items: center;
      padding: 1rem 0;

      .sectionItem {
        display: flex;
        justify-content: flex-end;
        align-items: center;

        .uploader {
          display: flex;
          width: 13.75rem;
          margin: 0 1rem;
        }
      }
      .removeElem {
        width: 1.875rem;
        height: 1.875rem;
        padding: 0 1rem;
      }
    }

    .seo {
      & > div > label {
        text-align: left;
      }
    }
  }
}
</style>
